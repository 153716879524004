html,
body {
  max-width: 100%;
  /* overflow-x: hidden; */
  margin: 0;
  /* height: 100%; */
  padding: 0;
  font-family: 'Inter' !important ;
}
@media print {
  body {
    overflow-x: visible;
  }
}

h3 {
  margin: 0;
}

img,
svg {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

b {
  font-weight: 600;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.align-right {
  text-align: right;
}

/*
 Theme
*/

table.simple-table td,
table.simple-table td {
  padding: 5px;
}

@font-face {
  font-family: 'Avenir';
  src: url(../styles/fonts/AvenirNext-Medium.woff) format('woff'),
    url(../styles/fonts/AvenirNext-Medium.ttf) format('truetype'),
    url(../styles/fonts/AvenirNext-Medium.eot) format('embedded-opentype');
}

@font-face {
  font-family: 'Avenir-Regular';
  src: url(../styles/fonts/avenir-next-regular.woff) format('woff'),
    url(../styles/fonts/avenir-next-regular.ttf) format('truetype'),
    url(../styles/fonts/avenir-next-regular.eot) format('embedded-opentype');
}

@font-face {
  font-family: 'Avenir-DemiBold';
  src: url(../styles/fonts/AvenirNext-DemiBold.woff) format('woff'),
    url(../styles/fonts/AvenirNext-DemiBold.ttf) format('truetype'),
    url(../styles/fonts/AvenirNext-DemiBold.eot) format('embedded-opentype');
}

@font-face {
  font-family: 'Avenir-Next-LT-Pro';
  src: url(../styles/fonts/avenirNextLTPro.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir-Next-LT-Pro';
  src: url(../styles/fonts/avenirNextLTProMedium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir-Next-LT-Pro';
  src: url(../styles/fonts/avenirNextLTProDemi.ttf) format('truetype');
  font-weight: 700;
}

body,
html {
  font-family: 'Avenir';
}

header .ms-Button {
  height: 40px;
}

header .ms-Button--icon {
  width: 40px;
}

.palette-more {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: block;
}

/* LOADING */
@keyframes dot-keyframes {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading-dots {
  text-align: center;
  width: 100%;
}
.loading-dots--dot {
  -webkit-animation: dot-keyframes 1.5s infinite ease-in-out;
  animation: dot-keyframes 1.5s infinite ease-in-out;
  border-radius: 10px;
  display: inline-block;
  height: 7px;
  width: 7px;
  margin: 0 2px;
  opacity: 0;
}
.loading-dots--dot:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loading-dots--dot:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ms-Dropdown-label {
  width: 100%;
}
.ms-Label {
  font: inherit;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 19.36px;
  background-color: #fff;
  color: #000000;
  padding: 0px;
}
h3 {
  font: inherit;
  font-weight: 700;
  font-size: 17px !important;
  line-height: 21.78px;
  background-color: #fff;
  color: #000000;
}
h1 {
  height: 29px;
  gap: 8px;
}
.ms-TextField-fieldGroup {
  background: none;
  border: 1px solid rgba(31, 31, 31, 0.2);
}

.labelTextView {
  padding-top: 3px;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 17px;
  line-height: 19.36px;
  color: #3a3a3a;
}
.labelText {
  font-weight: 400;
  font-size: 17px;
  line-height: 19.36px;
  color: #3a3a3a;
}
.css-241 {
  font-size: 17px !important;
}
/* .ms-TextField--multiline {
  min-width: 650px;
} */

.spa_overview th {
  text-align: left !important;
}

@media (min-width: 480px) {
  .ms-Dialog-main {
    max-width: 480px !important;
  }
}
.formdisabled {
  pointer-events: none;
  opacity: 0.8;
}
.ms-DetailsRow-fields {
  height: 45px;
}
.rowCellBold span {
  font-weight: bold !important;
  font-size: 14px !important;
}
.wrapper {
  padding-left: 15px !important;
  padding-right: 1% !important;
  background-color: #fff !important;
}
.errorDashboardWrapper {
  margin: 50px 15px 0px 80px;
  background-color: #f4f4f4 !important;
}
.wrapperram {
  padding-left: 5px !important;
  padding-right: 1% !important;
  background-color: #fff !important;
}
.viewSubHeader {
  padding-left: 15px;
}
@media only screen and (min-width: 1407px) {
  .dashboardApploved {
    margin: 10px 0px 10px 0px !important;
  }
}
.questinaryWrapper {
  padding: 64px 16px;
  background-color: white;
  width: 324px;
}
.questionaryStyles {
  padding: 32px 16px;
  top: 50;
  position: fixed;
  border-radius: 4;
  border: 1px solid #000;
  background-color: #f5f5f5;
}
.ms-Label {
  padding: 16px 0px 5px 0px !important;
}
.pdfLabel {
  padding-top: 10px !important;
}

.ms-DatePicker:not(:first-child) {
  margin-left: 60px !important;
}
.ms-TextField:not(:first-child) {
  margin-left: 60px !important;
}
.ms-Dropdown-container:not(:first-child) {
  margin-left: 60px !important;
}
.ms-Dropdown-title {
  height: 40px !important;
  padding: 4px 28px 0px 8px;
}
.ms-Dropdown-caretDownWrapper {
  top: 4px !important;
}
.ms-TextField-fieldGroup {
  height: 40px !important;
}
.ms-SearchBox {
  height: 40px !important;
}
.data-table-185 .ms-List-cell {
  min-height: 28px !important;
}
.ms-Button {
  margin-left: 10px !important;
}
/* .ms-Button:not(:disabled) i {} */
.dashboardIcon:not(:disabled) i {
  color: #007680 !important;
}
.dashboardIcon {
  margin-left: 4px !important;
}
.USDColumn {
  justify-content: end !important;
}
.rowCell span {
  font-size: 14px !important;
}
.cancel {
  border: none !important;
  color: #007680 !important;
}
.cancel:hover {
  background-color: #fff;
}
.saveEdit {
  border: 1px solid #007680 !important;
  color: #007680 !important;
}
.submitBtn {
  background-color: #007680 !important;
  color: #ffffff !important;
}
.deleteBtn {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  margin-left: 0px !important;
}
.deleteBtn:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.settingIcon {
  color: #fff;
}
.settingIcon:hover {
  background-color: transparent !important;
  color: #fff;
}
.settingIcon i {
  color: #fff;
}
.settingIcon .ms-Button-icon:hover {
  color: #fff;
}
.ms-Spinner-circle {
  width: 100px;
  height: 100px;
  border-width: 10px;
}
.ms-Spinner-label {
  font-size: 30px;
}
.descBtn {
  margin-left: 3px !important;
}
.cardWrapper {
  border: 1px solid #84744d;
  margin-top: 16px;
}
.cardHeader {
  background-color: #84744d;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 10px;
}
.breadcrumb {
  color: #0e687b;
  font-size: 24px;
}
.breadcrumb:hover {
  color: #0e687b !important;
}
.buttonbtn {
  background-color: #ffffff;
  position: fixed;
  flex-direction: column;
  right: 0;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px -3px 15px -5px rgb(163, 162, 160);
  -webkit-box-shadow: 0px -3px 15px -5px rgb(163, 162, 160);
  -moz-box-shadow: 0px -3px 15px -5px rgb(163, 162, 160);
}

.formStyle {
  display: block;
  flex-direction: column;
  grid-row-gap: 15px;
  color: #000000;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  width: auto;
  background-color: #ffffff;
}

.form-width-320 {
  min-width: 320px;
}
.form-width-400 {
  min-width: 400px;
}
.menuLink {
  color: #007680 !important;
  padding: 8px 10px;
  margin-left: 10px;
}
.menuLink:hover {
  text-decoration: none;
  background-color: #f3f1ec;
  margin-left: 8px;
  border-left: 2px solid #4c9da8;
}
.ms-Panel-content {
  width: auto !important;
  height: auto !important ;
  padding-right: 16px;
}

.boxicon-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}

.boxicon {
  margin: 0px;
  animation-iteration-count: 1;
  z-index: 2;
}
.boxicon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 95%;
  /* background-color: #002a3a; */
  opacity: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  color: #fff;
}
.boxicon:hover {
  color: #3977da;
}

.boxicon-container:hover > .boxicon::after {
  opacity: 1;
  transition: 0.1s;
  color: #fff;
}

.questionIcon {
  color: #ffffff;
  background-color: #002a3a !important;
  font-size: 36px;
  border-radius: 50%;
}
.questionIcon:hover {
  color: #ffffff;
  background-color: #002a3a !important;
  font-size: 36px;
  border-radius: 50%;
}
.questionIcon i {
  color: #fff;
}
.questionIcon .ms-Button-icon:hover {
  color: #fff;
}
@media only screen and (max-width: 1804px) {
  .rightSideQuestionIcon {
    display: none;
  }
  .smallQuestionContainer {
    display: flex !important;
    justify-content: flex-end;
  }
}
.spaErrorCell {
  font-size: 14px !important;
  font-family: Inter !important;
}

.ms-OverflowSet-item > button {
  margin: 0 !important;
}

.cardWrapper .accountNumber {
  margin-left: 70px !important;
}
.closeLabel {
  font-size: 18px !important;
  margin-top: 0px !important;
  color: #3a3a3a;
  font-weight: 400;
  text-align: center;
  padding: 10px 0px !important;
}
.clientName {
  color: #3a3a3a;
  font-weight: bold;
}
.clientName:active {
  color: #3a3a3a;
}
.clientName:hover {
  color: #045caa;
}
.spaErrorDiv {
  margin: 5px 5px 20px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cashRaiseDropdown .ms-Dropdown-title {
  height: 26px !important;
  padding: 0px 26px 0px 8px;
  line-height: 24px;
}
.cashRaiseDropdown .ms-Dropdown-caretDownWrapper {
  height: 17px;
  line-height: 17px;
}
.cashRaiseStatusColumn {
  padding: 0px !important;
}
.topFilter .ms-Label {
  padding: 0 !important;
  font-size: 15px !important;
}
.commentCellTooltip .ms-TextField-fieldGroup {
  min-height: 15px !important;
}
.errorDataTable .ms-DetailsRow-fields {
  height: auto !important;
}